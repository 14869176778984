<!-- 新增库存 -->
<template>
    <div class="content-panel">
        <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
            <el-col :span="20">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/admin/stock'}">{{msg('库存管理')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{msg('新增')}}</el-breadcrumb-item>
                </el-breadcrumb>
            </el-col>
        </el-row>
        <el-row :gutter="10"  class="ctrl-wrap">
            <el-col :sm="24" :md="12">
                <div style="display:flex;">
                    <el-radio-group type="info" v-model="strategy" size="small" >
                        <el-radio-button label="4" :disabled="strategy==4?false:true">新增库存</el-radio-button>
                        <el-radio-button label="1" :disabled="strategy==1?false:true">新增线下订单</el-radio-button>
                        <el-radio-button label="2" :disabled="strategy==2?false:true">新增店配订单</el-radio-button>
                    </el-radio-group>
                </div>
            </el-col>
        </el-row>
        <el-row :gutter="10"  class="ctrl-wrap">
        <el-form ref="form" :model="stock" label-width="100px" style="margin:0 20px">
            <h3>必填项</h3>
            <!-- 新增库存必填项 -->
            <!-- end -->
            <!-- 新增线下订单必填项 -->
            <el-row v-if="strategy==1">
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('收件人')">
                        <el-autocomplete
                            class="inline-input"
                            v-model="stock.receiver1"
                            :fetch-suggestions="(queryString,callback,keyword) => querySearch(queryString, callback, 'receiver1')"
                            placeholder="请输入收件人姓名"
                            size="small"
                        ></el-autocomplete>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('收件人电话')">
                        <el-autocomplete
                            class="inline-input"
                            v-model.trim="stock.receiverTel1"
                            :fetch-suggestions="(queryString,callback,keyword) => querySearch(queryString, callback, 'receiverTel1')"
                            placeholder="请输入收件人电话"
                            size="small"
                        ></el-autocomplete>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('配送方式')">
                        <el-select v-model="stock.expCompany1" style="margin-bottom:5px;"
                            clearable :placeholder="msg('选择配送方式')" size="small">
                            <el-option
                                v-for="item in expCompanys"
                                :key="item.value" 
                                :label="item.label"
                                :value="item.value"> 
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('运输方式')">
                        <el-select v-model="stock.transport1"  placeholder="供参考，实际以出货为准" size="small">
                            <el-option
                                v-for="item in dicts.transportTypeInout"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('物品描述')">
                        <el-input
                            type="text"
                            placeholder="请输入物品描述"
                            size="small"
                            v-model="stock.productTitle1"
                            maxlength="6"
                            @input="productTitleInput"
                            show-word-limit
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('代收款')">
                        <el-input-number v-model="stock.amtOrder1" :min="0" :precision="2" size="small"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-if="strategy==1">
                <el-col :xs="24" :sm="16">
                    <el-form-item :label="msg('收件人地址')">
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 4}"
                            placeholder="请输入收件人地址"
                            maxlength="50"
                            show-word-limit
                            v-model="stock.receiverAddr1">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-if="strategy==1">
                <el-col :xs="24" :sm="8" >
                    <el-form-item :label="msg('收件门市名')">
                        <el-autocomplete
                            class="inline-input"
                            style="width:100%"
                            v-model="stock.receiverShopName1"
                            :fetch-suggestions="(queryString,callback,keyword) => querySearch(queryString, callback, 'receiverShopName1')"
                            placeholder="出7-11、全家、OK、萊爾富 必填"
                            size="small"
                        ></el-autocomplete>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8" >
                    <el-form-item :label="msg('门店号区码')">
                        <el-autocomplete
                            class="inline-input"
                            style="width:100%"
                            v-model.trim="stock.receiverShopCode1"
                            :fetch-suggestions="(queryString,callback,keyword) => querySearch(queryString, callback, 'receiverShopCode1')"
                            placeholder="出7-11、全家、OK、萊爾富 必填"
                            size="small"
                        ></el-autocomplete>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- <el-row v-if="strategy==1">
            </el-row> -->
            <!-- end -->
            <!-- 新增店配订单必填项 -->
            <el-row v-if="strategy==2">
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('订单编号')">
                        <el-input v-model.trim="stock.code2" :placeholder="msg('请输入订单编号')" size="small"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('配送方式')">
                        <el-select v-model="stock.expCompany2" style="margin-bottom:5px;"
                            clearable :placeholder="msg('选择配送方式')" size="small">
                            <el-option
                                v-for="item in expCompanys"
                                :key="item.value" 
                                :label="item.label"
                                :value="item.value"> 
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('运输方式')">
                        <el-select v-model="stock.transport2"  placeholder="供参考，实际以出货为准" size="small">
                            <el-option
                                v-for="item in dicts.transportTypeInout"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-if="strategy==2">
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('物品描述')">
                        <el-input
                            type="text"
                            placeholder="请输入物品描述"
                            size="small"
                            v-model="stock.productTitle2"
                            @input="productTitleInput"
                            maxlength="6"
                            show-word-limit
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('店铺名')">
                        <el-autocomplete
                            class="inline-input"
                            v-model="stock.shopName2"
                            :fetch-suggestions="(queryString,callback,keyword) => querySearch(queryString, callback, 'shopName2')"
                            placeholder="请输入店铺名"
                            size="small"
                        ></el-autocomplete>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- end -->
            <!-- 新增现货仓储必填项 -->
            <el-row v-if="strategy==3">
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('收件人')">
                        <el-select v-model="stock.receiver3"  placeholder="请选择" size="small"
                            clearable
                        >
                            <el-option
                                v-for="item in stores"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.name">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- end -->
            <h3>选填项</h3>
            <!-- 新增库存选填项 -->
            <el-row v-if="strategy==4">
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('订单编号')">
                        <el-input v-model.trim="stock.code" :placeholder="msg('可填可不填，不填由系统生成')" size="small"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="7">
                    <el-form-item :label="msg('物品描述')">
                        <el-input
                            type="text"
                            placeholder="请输入物品描述"
                            size="small"
                            v-model="stock.productTitle"
                            maxlength="6"
                            @input="productTitleInput"
                            show-word-limit
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="9">
                    <el-form-item :label="msg('架位号')">
                        <!-- <el-autocomplete
                            class="inline-input"
                            v-model.trim="stock.shelfCode"
                            :fetch-suggestions="(queryString,callback,keyword) => querySearch(queryString, callback, 'shelfCode')"
                            placeholder="请输入架位号"
                            size="small"
                        ></el-autocomplete> -->
                        <el-input v-model.trim="stock.shelfCode" :placeholder="msg('请输入架位号')" size="small"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-if="strategy==4">
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('货物类型')">
                        <el-select v-model="stock.expGoodType" style="margin-bottom:5px;"
                            placeholder="选择货物类型" size="small">
                            <el-option
                                v-for="item in this.dicts.stockExpGoodType"
                                :key="item.value" 
                                :label="item.label"
                                :value="item.value"> 
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('金额')">
                        <el-input-number v-model="stock.amtOrder" :min="0" :precision="2" size="small"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8" :md="6" style="margin-top:5px;">
                    <el-form-item :label="msg('归属人')" label-width="60px">
                        <el-autocomplete size="small"
                            v-model="stock.ownerName"
                            :fetch-suggestions="searchUser"
                            :placeholder="this.msg('搜索归属人')"
                            :trigger-on-focus="false"
                            @select="selectUser"
                            @clear="clearUsername"
                        ></el-autocomplete>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-if="strategy==4">
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('报单备注')">
                        <!-- <el-autocomplete
                            class="inline-input"
                            v-model="stock.remark1"
                            :fetch-suggestions="(queryString,callback,keyword) => querySearch(queryString, callback, 'remark1')"
                            placeholder="请输入报单备注"
                            size="small"
                        ></el-autocomplete> -->
                        <el-input v-model.trim="stock.remark1" :placeholder="msg('请输入报单备注')" size="small"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('货单备注')">
                        <!-- <el-autocomplete
                            class="inline-input"
                            v-model="stock.remark2"
                            :fetch-suggestions="(queryString,callback,keyword) => querySearch(queryString, callback, 'remark2')"
                            placeholder="请输入货单备注"
                            size="small"
                        ></el-autocomplete> -->
                        <el-input v-model.trim="stock.remark2" :placeholder="msg('请输入货单备注')" size="small"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('仓库')">
                        <el-select v-model="stock.storeId"  placeholder="请选择" size="small"
                            clearable @clear="clearStore"
                        >
                            <el-option
                                v-for="item in stores"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- end -->
            <!-- 新增线下订单选填项 -->
            <el-row v-if="strategy==1">
                <el-col :xs="24" :sm="6">
                    <el-form-item :label="msg('订单编号')">
                        <el-input v-model.trim="stock.code1" :placeholder="msg('可填可不填，不填由系统生成')" size="small"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="6">
                    <el-form-item :label="msg('查询码')">
                        <el-input v-model.trim="stock.expCode1" :placeholder="msg('请输入查询码')" size="small"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="6">
                    <el-form-item :label="msg('店铺名')">
                        <el-autocomplete
                            class="inline-input"
                            v-model="stock.shopName1"
                            :fetch-suggestions="(queryString,callback,keyword) => querySearch(queryString, callback, 'shopName1')"
                            placeholder="请输入店铺名"
                            size="small"
                        ></el-autocomplete>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="6">
                    <el-form-item :label="msg('货物类型')">
                        <el-select v-model="stock.expGoodType1" style="margin-bottom:5px;"
                            clearable placeholder="选择货物类型" size="small">
                            <el-option
                                v-for="item in this.dicts.stockExpGoodType"
                                :key="item.value" 
                                :label="item.label"
                                :value="item.value"> 
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-if="strategy==1">
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('报单备注')">
                        <el-autocomplete
                            class="inline-input"
                            v-model="stock.remark11"
                            :fetch-suggestions="(queryString,callback,keyword) => querySearch(queryString, callback, 'remark11')"
                            placeholder="请输入报单备注"
                            size="small"
                        ></el-autocomplete>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('货单备注')">
                        <el-autocomplete
                            class="inline-input"
                            v-model="stock.remark21"
                            :fetch-suggestions="(queryString,callback,keyword) => querySearch(queryString, callback, 'remark21')"
                            placeholder="请输入货单备注"
                            size="small"
                        ></el-autocomplete>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- end -->
            <!-- 新增店配订单选填项 -->
            <el-row v-if="strategy==2">
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('货物类型')">
                        <el-select v-model="stock.expGoodType2" style="margin-bottom:5px;"
                            clearable placeholder="选择货物类型" size="small">
                            <el-option
                                v-for="item in this.dicts.stockExpGoodType"
                                :key="item.value" 
                                :label="item.label"
                                :value="item.value"> 
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('门店号区码')">
                        <el-autocomplete
                            class="inline-input"
                            v-model.trim="stock.receiverShopCode2"
                            :fetch-suggestions="(queryString,callback,keyword) => querySearch(queryString, callback, 'receiverShopCode2')"
                            placeholder="请输入门店号区码"
                            size="small"
                        ></el-autocomplete>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('收件门市名')">
                        <el-autocomplete
                            class="inline-input"
                            v-model="stock.receiverShopName2"
                            :fetch-suggestions="(queryString,callback,keyword) => querySearch(queryString, callback, 'receiverShopName2')"
                            placeholder="请输入收件门市名"
                            size="small"
                        ></el-autocomplete>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-if="strategy==2">
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('收件人')">
                        <el-autocomplete
                            class="inline-input"
                            v-model="stock.receiver2"
                            :fetch-suggestions="(queryString,callback,keyword) => querySearch(queryString, callback, 'receiver2')"
                            placeholder="请输入收件人"
                            size="small"
                        ></el-autocomplete>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('收件人电话')">
                        <el-autocomplete
                            class="inline-input"
                            v-model.trim="stock.receiverTel2"
                            :fetch-suggestions="(queryString,callback,keyword) => querySearch(queryString, callback, 'receiverTel2')"
                            placeholder="请输入收件人电话"
                            size="small"
                        ></el-autocomplete>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('金额')">
                        <el-input-number v-model="stock.amtOrder2" :min="0" :precision="2" size="small"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-if="strategy==2">
                <el-col :xs="24" :sm="16">
                    <el-form-item :label="msg('收件人地址')">
                        <!-- <el-autocomplete
                            class="inline-input"
                            v-model="stock.receiverAddr2"
                            :fetch-suggestions="(queryString,callback,keyword) => querySearch(queryString, callback, 'receiverAddr2')"
                            placeholder="请输入收件人地址"
                            size="small"
                        ></el-autocomplete> -->
                        <el-input
                            type="textarea"
                            :autosize="{ minRows: 2, maxRows: 4}"
                            placeholder="请输入收件人地址"
                            maxlength="50"
                            show-word-limit
                            v-model="stock.receiverAddr2">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-if="strategy==2">
                <!--
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('台湾物流公司')">
                        <el-select v-model="stock.expCompany2" style="margin-bottom:5px;"
                            clearable :placeholder="msg('选择台湾物流公司')" size="small">
                            <el-option
                                v-for="item in expCompanys"
                                :key="item.value" 
                                :label="item.label"
                                :value="item.value"> 
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                -->
            </el-row>
            <el-row v-if="strategy==2">
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('报单备注')">
                        <el-autocomplete
                            class="inline-input"
                            v-model="stock.remark12"
                            :fetch-suggestions="(queryString,callback,keyword) => querySearch(queryString, callback, 'remark12')"
                            placeholder="请输入报单备注"
                            size="small"
                        ></el-autocomplete>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('货单备注')">
                        <el-autocomplete
                            class="inline-input"
                            v-model="stock.remark22"
                            :fetch-suggestions="(queryString,callback,keyword) => querySearch(queryString, callback, 'remark22')"
                            placeholder="请输入货单备注"
                            size="small"
                        ></el-autocomplete>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- end -->
            <!-- 新增现货仓储选填项 -->
            <el-row v-if="strategy==3">
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('订单编号')">
                        <el-input v-model.trim="stock.code3" :placeholder="msg('可填可不填，不填由系统生成')" size="small"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('货物类型')">
                        <el-select v-model="stock.expGoodType3" style="margin-bottom:5px;"
                            clearable placeholder="选择货物类型" size="small">
                            <el-option
                                v-for="item in this.dicts.stockExpGoodType"
                                :key="item.value" 
                                :label="item.label"
                                :value="item.value"> 
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <!-- <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('打包类型')">
                        <el-select v-model="stock.expPkgType3" style="margin-bottom:5px;"
                            clearable placeholder="选择打包类型" size="small">
                            <el-option
                                v-for="item in this.dicts.stockExpPkgType"
                                :key="item.value" 
                                :label="item.label"
                                :value="item.value"> 
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col> -->
                <!-- <el-col :xs="24" :sm="8">
                    <el-form-item>
                        <el-checkbox v-model="stock.expPkgType3">货运代打包</el-checkbox>
                    </el-form-item>
                </el-col> -->
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('报单备注')">
                        <el-autocomplete
                            class="inline-input"
                            v-model="stock.remark13"
                            :fetch-suggestions="(queryString,callback,keyword) => querySearch(queryString, callback, 'remark13')"
                            placeholder="请输入报单备注"
                            size="small"
                        ></el-autocomplete>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-if="strategy==3">
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('货单备注')">
                        <el-autocomplete
                            class="inline-input"
                            v-model="stock.remark23"
                            :fetch-suggestions="(queryString,callback,keyword) => querySearch(queryString, callback, 'remark23')"
                            placeholder="请输入货单备注"
                            size="small"
                        ></el-autocomplete>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- end -->
            <h3>库存详情</h3>
            <el-table :data="stock.stockDetails" style="width: 100%;" size="mini" ref="dataTable" stripe>
                <el-table-column type="index" width="50"></el-table-column>
                <el-table-column :label="msg('标题')" min-width="120" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.productTitle" placeholder="请输入标题" size="mini" ></el-input>
                    </template>
                </el-table-column>
                <el-table-column :label="msg('规格')" min-width="100" >
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.productSpec" placeholder="请输入规格" size="mini" ></el-input>
                    </template>
                </el-table-column>
                <el-table-column :label="msg('件数')" min-width="90" >
                    <template slot-scope="scope">
                        <el-input-number v-model="scope.row.productQty" :min="1" size="mini"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column v-if="strategy==4" :label="msg('单价(元)')" min-width="90" >
                    <template slot-scope="scope">
                        <el-input-number v-model="scope.row.productPrice" size="mini"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column :label="msg('SKU')" min-width="100" >
                    <template slot-scope="scope">
                        <el-input v-model.trim="scope.row.sku" size="mini" ></el-input>
                    </template>
                </el-table-column>
                <el-table-column :label="msg('货号')" min-width="100" >
                    <template slot-scope="scope">
                        <el-input v-model.trim="scope.row.variationSku" size="mini" ></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="80">
                    <template slot-scope="scope">
                        <el-button type="danger" class="nopad" icon="el-icon-delete" size="mini" plain
                            @click.stop="stock.stockDetails.splice(scope.$index, 1)">
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="text-align:center; margin-top:5px;">
                <el-button type="primary" size="mini" plain @click="addDetails()">
                    <i class="el-icon-circle-plus-outline"></i>添加更多
                </el-button>
            </div>
        </el-form>

        <div style="text-align:center; margin-top: 15px;">
            <el-button type="primary" size="mini"  @click="onSubmit(0)"><i class="el-icon-check"></i><span>{{msg('确认新增')}}</span></el-button>
            <el-button v-if="strategy==4" type="primary" size="mini"  @click="onSubmit(1)"><i class="el-icon-check"></i><span>{{msg('新增并加入到快递管理')}}</span></el-button>
        </div>
        </el-row>

    </div>
</template>

<script>
export default {
    name: "StockAdd",
    components:{

    },
    data(){
        return {
            strategy: 4,
            stores: null,
            stock: {
                //库存
                code:null,
                productTitle:null,
                shelfCode:null,
                expGoodType:0,
                amtOrder:null,
                owner:null,
                ownerName:null,
                remark1:null,
                remark2:null,
                storeId:null,
                //线下
                receiver1:null,
                receiverTel1:null,
                expCompany1:null,
                receiverAddr1:null,
                receiverShopCode1:null,
                receiverShopName1:null,
                productTitle1:null,
                amtOrder1:null,
                code1:null,
                expCode1:null,
                // expPkgType1:false,
                shopName1:null,
                expGoodType1:0,
                remark11:null,
                remark21:null,
                //店配
                code2:null,
                productTitle2:null,
                shopName2:null,
                expGoodType2:0,
                // expPkgType2:false,
                receiverShopCode2:null,
                receiverShopName2:null,
                receiver2:null,
                receiverTel2:null,
                receiverAddr2:null,
                expCompany2:null,
                amtOrder2:null,
                remark12:null,
                remark22:null,
                //现货仓储
                code3:null,
                expGoodType3:0,
                receiver3:null,
                // expPkgType3:false,
                remark13:null,
                remark23:null,
                stockDetails:[{
                        productTitle:null,
                        productSpec:null,
                        productQty:1,
                        innerExpCode:null,
                        shelfCode:null,
                        productPrice:0,
                        sku:null,
                        variationSku:null,
                    }],
            },
            autostock: {
                code:[],
                productTitle:[],
                code1:[],
                productTitle1:[],
                receiverShopCode1:[],
                receiverShopName1:[],
                receiver1:[],
                receiverTel1:[],
                receiverAddr1:[],
                code2:[],
                productTitle2:[],
                shopName2:[],
                shelfCode:[],
                remark1:[],
                remark2:[],
                shopName1:[],
                remark11:[],
                remark21:[],
                receiverShopCode2:[],
                receiverShopName2:[],
                receiver2:[],
                receiverTel2:[],
                receiverAddr2:[],
                remark12:[],
                remark22:[],
                code3:[],
                remark13:[],
                remark23:[],
            },
            expCompanys:[],
        }
    },
    mounted(){
        let type = this.$route.params.type;
        if(type){
            this.strategy = type;
        }
        this.getStores();
        this.getCompanys();
        this.stock.stockDetails[0].productTitle=null;
    },
    watch:{
      $route(){
        //跳转到该页面后需要进行的操作
        let type = this.$route.params.type;
        if(type){
            this.strategy = type;
        }
        this.getStores();
        this.getCompanys();
        this.stock.stockDetails[0].productTitle=null;
      }
    },
    methods:{
        init(){
            this.stock = {
                //库存
                code:null,
                productTitle:null,
                shelfCode:null,
                expGoodType:0,
                amtOrder:null,
                owner:null,
                ownerName:null,
                remark1:null,
                remark2:null,
                storeId:null,
                //线下
                receiver1:null,
                receiverTel1:null,
                expCompany1:null,
                transport1:null,
                receiverAddr1:null,
                receiverShopCode1:null,
                receiverShopName1:null,
                productTitle1:null,
                amtOrder1:null,
                code1:null,
                expCode1:null,
                // expPkgType1:false,
                shopName1:null,
                expGoodType1:0,
                remark11:null,
                remark21:null,
                //店配
                code2:null,
                productTitle2:null,
                shopName2:null,
                transport2:null,
                expGoodType2:0,
                // expPkgType2:false,
                receiverShopCode2:null,
                receiverShopName2:null,
                receiver2:null,
                receiverTel2:null,
                receiverAddr2:null,
                expCompany2:null,
                amtOrder2:null,
                remark12:null,
                remark22:null,
                //现货仓储
                code3:null,
                expGoodType3:0,
                // expPkgType3:false,
                receiver3:null,
                remark13:null,
                remark23:null,
                stockDetails:[{
                        productTitle:null,
                        productSpec:null,
                        productQty:1,
                        innerExpCode:null,
                        shelfCode:null,
                        productPrice:0,
                        sku:null,
                        variationSku:null,
                    }],
            }
        },
        clearStore(){
            this.stock.storeId = null;
        },
        productTitleInput(value){
            this.stock.stockDetails[0].productTitle = value;
        },
        getStores(){
            this.$axios.post(this.$kit.api.store.listUsed)
                .then((result)=> {
                    if(result.status){
                        this.stores = result.data;
                    }
                });
        },
        getCompanys(){
            this.$axios.get(this.$kit.api.getExpCompany)
                .then((result)=> {
                    if(result.status){
                        this.expCompanys = result.data;
                    }
                });
        },
        querySearch(queryString,cb,keyword){

            let codes = [];

            this.autostock[keyword].forEach(e => {
                codes.push(
                    {value : e}
                )
            });
            var results = queryString ? codes.filter(this.createFilter(queryString)) : codes;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (restaurant) => {
            return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        searchUser(queryString, cbFn){  //搜索用户
            this.$axios.get(this.$kit.api.user.search+"?name="+encodeURIComponent(queryString)+"&type=1")
                .then((result) => {
                    if(result.status){
                        let users =[];
                        result.data.forEach(e => {
                            users.push({
                                id: e.id,
                                value: e.value
                            });
                        });
                        cbFn(users);
                    }
                });
        },
        selectUser(item) { //获取选中的用户
            this.stock.owner = item.id;
            this.stock.ownerName = item.value;
        },
        clearUsername(){
            this.stock.owner = null;
            this.stock.ownerName = null;
        },
        productQtyChange(currentValue, oldValue){
            this.stock.stockDetails[0].productQty = currentValue;
        },
        onSubmit(type){
            this.$refs["form"].validate((valid) => {
                if(valid){
                    var _this = this;
        
                    let newStock = null;
        
                    if(this.stock.stockDetails.length <= 0){
                        this.$message(this.msg('库存详情不能为空'))
                        return;
                    }

                    this.stock.stockDetails.forEach(e => {
                        if(e.productPrice && e.productPrice > 0){
                            e.productPrice = e.productPrice*100;
                        }
                    })

                    //新增线下7-11、全家、OK、萊爾富时，必需填门店号区码；如用户没填，提示：收件信息不完善，门市编号必须准确！
                    let reg = new RegExp("^[0-9]*$");
                    if(this.strategy == 1){
                        if(!this.stock.expCompany1){
                            this.$message(this.msg('请选择配送方式'))
                            return;
                        }else{
                            if(this.stock.expCompany1.indexOf('7-11') > -1 
                                || this.stock.expCompany1.indexOf('全家') > -1 
                                || this.stock.expCompany1.indexOf('萊爾富') > -1 
                                || this.stock.expCompany1.indexOf('OK') > -1 ){

                                    if(!this.stock.receiverShopCode1){
                                        this.$message(this.msg('收件信息不完善，门店号区码必须准确！'))
                                        return;
                                    }
                                    if(this.stock.receiverTel1){
                                        if(!reg.test(this.stock.receiverTel1) || this.stock.receiverTel1.length != 10){
                                            this.$message(this.msg('电话格式不正确：7-11、全家、OK、萊爾富电话号码必须为10位纯数字，其它为10-12位的纯数字'))
                                            return;
                                        }
                                    }else{
                                        this.$message(this.msg('收件人电话不能为空'))
                                        return;
                                    }
                            }else{
                                if(this.stock.receiverTel1){
                                    if(!reg.test(this.stock.receiverTel1) || this.stock.receiverTel1.length < 10 || this.stock.receiverTel1.length > 12){
                                        this.$message(this.msg('电话格式不正确：7-11、全家、OK、萊爾富电话号码必须为10位纯数字，其它为10-12位的纯数字'))
                                        return;
                                    }
                                }else{
                                    this.$message(this.msg('收件人电话不能为空'))
                                    return;
                                }
                                //除四大超商外，其他配送方式，收件人地址必填
                                if(!this.stock.receiverAddr1){
                                    this.$message(this.msg('收件人地址不能为空'))
                                    return;
                                }
                            }
                        }

                    }

                    if(type == 1){
                        if(!this.stock.storeId || this.stock.storeId == 0){
                            this.$message(this.msg('单号加入快递管理列表需选择仓库，请正确选择快递寄往的仓库后再提交'))
                            return;
                        }
                    }

                    if(this.strategy == 4){//新增库存
                        newStock = {
                            code:this.stock.code,
                            productName:this.stock.productTitle,
                            shelfCode:this.stock.shelfCode,
                            expGoodType:this.stock.expGoodType,
                            amtOrder:this.stock.amtOrder*100,
                            owner:this.stock.owner,
                            remark1:this.stock.remark1,
                            remark2:this.stock.remark2,
                            storeId:this.stock.storeId?this.stock.storeId:0,
                        }
                        // if(this.autostock.code.indexOf(this.stock.code) < 0){
                        //     this.autostock.code.push(this.stock.code);
                        // }
                    }else if(this.strategy == 1){//新增线下订单
                        newStock = {
                            receiver:this.stock.receiver1,
                            receiverTel:this.stock.receiverTel1,
                            expCompany:this.stock.expCompany1,
                            transport:this.stock.transport1,
                            receiverAddr:this.stock.receiverAddr1,
                            receiverShopCode:this.stock.receiverShopCode1,
                            receiverShopName:this.stock.receiverShopName1,
                            productName:this.stock.productTitle1,
                            amtOrder:this.stock.amtOrder1*100,
                            code:this.stock.code1,
                            expCode:this.stock.expCode1,
                            // expPkgType:(this.stock.expPkgType1)?5:1,
                            shopName:this.stock.shopName1,
                            expGoodType:this.stock.expGoodType1,
                            remark1:this.stock.remark11,
                            remark2:this.stock.remark21,
                        }
                    }else if(this.strategy == 2){//新增店配订单
                        newStock = {
                            code:this.stock.code2,
                            productName:this.stock.productTitle2,
                            shopName:this.stock.shopName2,
                            expGoodType:this.stock.expGoodType2,
                            transport:this.stock.transport2,
                            // expPkgType:(this.stock.expPkgType2)?5:1,
                            receiverShopCode:this.stock.receiverShopCode2,
                            receiverShopName:this.stock.receiverShopName2,
                            receiver:this.stock.receiver2,
                            receiverTel:this.stock.receiverTel2,
                            receiverAddr:this.stock.receiverAddr2,
                            expCompany:this.stock.expCompany2,
                            amtOrder:this.stock.amtOrder2*100,
                            remark1:this.stock.remark12,
                            remark2:this.stock.remark22,
                        }
                    }else if(this.strategy == 3){//新增现货仓储
                        newStock = {
                            code:this.stock.code3,
                            expGoodType:this.stock.expGoodType3,
                            receiver:this.stock.receiver3,
                            // expPkgType:(this.stock.expPkgType3)?5:1,
                            remark1:this.stock.remark13,
                            remark2:this.stock.remark23,
                        }
                    }

                    let is = true;
                    if(this.strategy == 3){
                        this.stock.stockDetails.forEach(e => {
                                if(!e.productTitle || !e.productSpec){
                                    is = false;
                                }
                            })
                    }
                    if(!is){
                        this.$message(this.msg("新增现货仓储，详情标题和规格不能为空！！"));
                        return;
                    }

                    newStock["stockDetails"] = this.stock.stockDetails;
        
                    this.$axios.post(this.$kit.api.stock.create+"?type="+this.strategy+"&saveType="+type,newStock)
                        .then(function(result){
                            if(result.status){
                                _this.$alert(_this.msg(result.msg), _this.msg('提示'), {
                                    confirmButtonText: '确定',
                                    callback: () => {
                                        _this.init();
                                    }
                                });
                                
                            }else{
                                // _this.$message(result.msg || _this.msg('添加失败'));
                                _this.$alert(_this.msg(result.msg), _this.msg('提示'), {
                                    confirmButtonText: '确定',
                                });
                            }
                        });
                }
            })
        },
        addDetails(){
            var detail = {
                productTitle:'',
                productSpec:'',
                productQty:1,
                // innerExpCode:'',
                // shelfCode:'',
                remark:'',
            }
            this.stock.stockDetails.push(detail);
        },
    }

}
</script>

<style lang="scss" scoped>
.xmain .router-tab-page {
    height: calc(100vh - 100px);
}
</style>